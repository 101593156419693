import * as React from 'react'

import { useFlash, useModal } from '@contexts'
import { CustomButton, Loading, Modal, TextH2Bold } from '@components'
import { Images } from '@images'
import { useSessionUserUpdater, useTranslation } from '@hooks'
import { useRequestRefund } from '@features/Coins/hooks/usePayments'

interface Props {
  paymentId: string
}
const RequestRefundConfirmModal = (props: Props) => {
  const { paymentId } = props
  const { t } = useTranslation()
  const { dismissModal } = useModal()
  const { showFlash } = useFlash()
  const { refresh } = useSessionUserUpdater()

  const { requestRefund, isLoading } = useRequestRefund({
    onSuccess: () => {
      refresh()
      dismissModal()
      showFlash({ message: t('payments.modals.request-refund.success'), severity: 'success' })
    },
    onError: () => {
      dismissModal()
      showFlash({ message: t('payments.modals.request-refund.error'), severity: 'error' })
    },
  })

  return (
    <Modal.Content>
      <Modal.Header image={Images.WarningImage}>
        <TextH2Bold>{t('payments.modals.request-refund.title')}</TextH2Bold>
      </Modal.Header>
      {isLoading && <Loading />}
      <Modal.Actions>
        <CustomButton
          variant={'secondary'}
          label={t('payments.modals.request-refund.actions.back')}
          fullWidth={true}
          onClick={() => dismissModal()}
        />
        <CustomButton
          variant={'primary'}
          label={t('payments.modals.request-refund.actions.submit')}
          fullWidth={true}
          onClick={() => {
            requestRefund(paymentId)
          }}
          disabled={isLoading}
        />
      </Modal.Actions>
    </Modal.Content>
  )
}

export { RequestRefundConfirmModal }
