import * as process from 'process'

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { QueryClient, QueryClientProvider, setLogger } from 'react-query'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { GoogleOAuthProvider } from '@react-oauth/google'

import { config } from '@config'
import { theme } from '@styles'
import { FlashProvider, MenuDrawerProvider, ModalProvider, UserProvider } from '@contexts'
import { setAuthHeader } from '@api'
import { LOCAL_STORAGE_KEYS, ROUTES } from '@constants'
import { RankingMain } from '@features/Rankings/screens'
import { PollView } from '@features/Polls/screens'

import { PrivacyPolicy, TermsAndConditions } from './Pages'
import { CoinsMain, PaymentsMain } from './Coins'
import { LandingMain } from './Landing'
import { ProfileMain } from './Profile'
import { CreateAccountMain } from './CreateAccount'
import { TriviaView, TriviaMain, TriviaPrevious, TriviaRanking } from './Trivias'
import { ChallengeMain, ChallengeView } from './Challenges'
import { GalleryMain, GalleryItemView } from './Gallery'
import { MarketplaceItemView, MarketplaceMain } from './Marketplace'
import { MyCollectionMain, MyCollectionItemView } from './MyCollection'
import { MuseumItemView, MuseumMain } from './Museum'
import { PredictionsMain, PredictionsView } from './Predictions'
import { DigitizeMain } from './Digitize'

const queryClient = new QueryClient()
if (process.env.NODE_ENV !== 'development') {
  setLogger({
    log: () => {},
    warn: () => {},
    error: () => {},
  })
}
setAuthHeader(window.localStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN))

function App() {
  return (
    <GoogleOAuthProvider clientId={config.googleClientID}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <MenuDrawerProvider>
            <FlashProvider>
              <Router>
                <QueryClientProvider client={queryClient}>
                  <>
                    <UserProvider>
                      <ModalProvider>
                        <Routes>
                          <Route path={ROUTES.HOME} element={<LandingMain />} />
                          <Route path='/login' element={<LandingMain />} />
                          <Route path='/profile' element={<ProfileMain />} />
                          <Route path='/sign-up' element={<CreateAccountMain />} />
                          <Route path={ROUTES.PREDICTIONS} element={<PredictionsMain />} />
                          <Route path={ROUTES.PREDICTIONS + '/:id'} element={<PredictionsView />} />
                          <Route path={ROUTES.DIGITIZE} element={<DigitizeMain />} />
                          <Route path={ROUTES.TRIVIAS} element={<TriviaMain />} />
                          <Route path={ROUTES.PREVIOUS_TRIVIAS} element={<TriviaPrevious />} />
                          <Route path={ROUTES.RANKING_TRIVIAS + '/:id'} element={<TriviaRanking />} />
                          <Route path={ROUTES.TRIVIAS + '/:id'} element={<TriviaView />} />
                          <Route path={ROUTES.CHALLENGES} element={<ChallengeMain />} />
                          <Route path={ROUTES.CHALLENGES + '/:id'} element={<ChallengeView />} />
                          <Route path={ROUTES.MY_COLLECTIBLES} element={<MyCollectionMain />} />
                          <Route path={ROUTES.MY_COLLECTIBLES + '/:id'} element={<MyCollectionItemView />} />
                          <Route path={ROUTES.MY_SHIRTS} element={<GalleryMain />} />
                          <Route path={ROUTES.MY_SHIRTS + '/:id'} element={<GalleryItemView />} />
                          <Route path={ROUTES.MARKETPLACE} element={<MarketplaceMain />} />
                          <Route path={ROUTES.MARKETPLACE + '/:id'} element={<MarketplaceItemView />} />
                          <Route path='/museum' element={<MuseumMain />} />
                          <Route path='/museum/:id' element={<MuseumItemView />} />
                          <Route path='/auth/google-redirect' element={<LandingMain />} />
                          <Route path={ROUTES.COINS} element={<CoinsMain />} />
                          <Route path={ROUTES.PAYMENTS} element={<PaymentsMain />} />
                          <Route path={ROUTES.RESET_PASSWORD} element={<LandingMain />} />
                          <Route path={ROUTES.VALIDATE_EMAIL} element={<LandingMain />} />
                          <Route path={ROUTES.RANKINGS} element={<RankingMain />} />
                          <Route path={ROUTES.POLLS + '/:id'} element={<PollView />} />
                          <Route path={ROUTES.PAGE_TERMS} element={<TermsAndConditions />} />
                          <Route path={ROUTES.PAGE_PRIVACY} element={<PrivacyPolicy />} />
                        </Routes>
                      </ModalProvider>
                    </UserProvider>
                  </>
                </QueryClientProvider>
              </Router>
            </FlashProvider>
          </MenuDrawerProvider>
        </ThemeProvider>
      </LocalizationProvider>
    </GoogleOAuthProvider>
  )
}

export default App
