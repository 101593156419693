import { Collectible } from '@types'

export enum TransactionType {
  GIFT = 'GIFT',
  COLLECTIBLE_EXCHANGE = 'COLLECTIBLE_EXCHANGE',
  PRIZE = 'PRIZE',
  BUY = 'BUY',
  REFUND = 'REFUND',
}

export type TrxUser = {
  id: number
  username: string
}
export interface Transaction {
  id: number
  description: string
  sender: TrxUser
  receiver: TrxUser
  amount: number
  type: TransactionType
  collectible?: Collectible
  createdAt: Date
}

export enum ProductType {
  COINS = 'COINS',
  ACTIVATION_CODE = 'ACTIVATION_CODE',
}

export enum PaymentStatus {
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  CANCELED = 'CANCELED',
  EXPIRED = 'EXPIRED',
  ERROR = 'ERROR',
  REFUNDED = 'REFUNDED',
}
export interface Payment {
  id: number
  createdAt: Date
  updatedAt: Date
  orderId: string
  paymentId?: string
  amount: number
  currency: CoinsCurrency
  productType: ProductType
  coins: number
  status: PaymentStatus
}

export interface CreatePaymentResponse {
  redirect: string
  paymentId: string
  orderId: string
  paymentToken: string
}

export enum CoinsCurrency {
  EUR = 'EUR',
  USD = 'USD',
}

export interface CoinsBudget {
  amount: number
  currency: CoinsCurrency
  coins?: number
}
