import * as React from 'react'
import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import { useMediaQuery } from '@mui/material'
import { PropsWithChildren } from 'react'

import { CustomButton, TextH2Bold, TextSubtitleRegular } from '@components'
import { colors, MediaQuery, MqDesktopXL } from '@styles'
import { NewsHorizontalWidgetType } from '@features/Widgets/types'

import { WidgetContent } from '../WidgetContent'

const NewsHorizontalWidgetImage = styled('img')(({ theme }) => ({
  borderRadius: theme.spacing(2),
  borderColor: colors.grayscale['30'].background,
  borderWidth: 1,
  borderStyle: 'solid',
  width: '50%',
  height: '100%',
  objectFit: 'cover',
}))

const NewsHorizontalWidgetDetailsColumn = (props: PropsWithChildren) => {
  const isDesktop = useMediaQuery(MediaQuery[MqDesktopXL].replace(/^@media( ?)/m, ''))

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'flex-start'}
      flexWrap={'wrap'}
      gap={3}
      sx={{ width: isDesktop ? '50%' : '100%' }}
    >
      {props.children}
    </Box>
  )
}
const NewsHorizontalWidget = (props: Omit<NewsHorizontalWidgetType, 'type'>) => {
  const { title, description, actionName, imageUrl, actionTarget } = props

  const onClick = actionTarget ? () => window.location.replace(actionTarget) : undefined
  const isDesktop = useMediaQuery(MediaQuery[MqDesktopXL].replace(/^@media( ?)/m, ''))

  return (
    <WidgetContent>
      <Box display={'flex'} flexDirection={isDesktop ? 'row' : 'column'} p={2} gap={2.5} sx={{ width: '100%' }}>
        <NewsHorizontalWidgetImage
          src={imageUrl}
          sx={isDesktop ? { width: '50%' } : { width: '100%', minHeight: 250, objectPosition: 'top' }}
        />
        <NewsHorizontalWidgetDetailsColumn>
          <TextH2Bold>{title}</TextH2Bold>
          {!!description && <TextSubtitleRegular>{description}</TextSubtitleRegular>}
        </NewsHorizontalWidgetDetailsColumn>
      </Box>
      {!!actionName && !!onClick && (
        <CustomButton
          variant={'secondary'}
          label={actionName}
          sx={{ mt: 2, fontWeight: 700, fontSize: 18 }}
          onClick={onClick}
          fullWidth
        />
      )}
    </WidgetContent>
  )
}

export { NewsHorizontalWidget }
