import Box from '@mui/material/Box'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'

import { AppBase } from '@features/AppBase'
import { useTranslation } from '@hooks'
import { Images } from '@images'
import { Content, CustomButton, Header, Title } from '@components'
import { PaymentsHistory } from '@features/Coins/components/PaymentsHistory'

const PaymentsMain = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <AppBase requireActiveSession={true} backgroundImage={Images.WalletBackground}>
      <Box width={'100%'} px={{ md: 16.5, sm: 8, xs: 2 }} py={4}>
        <Header>
          <CustomButton
            variant={'secondary'}
            label={t('payments.back')}
            startIcon={['fas', 'chevron-left']}
            onClick={() => navigate(-1)}
          />
          <Title text={t('payments.title')} />
        </Header>

        <Content
          flexDirection={{ md: 'row', xs: 'column' }}
          sx={{ alignItems: 'flex-start', width: '100%', justifyContent: 'space-between', flexWrap: 'nowrap' }}
        >
          <PaymentsHistory />
        </Content>
      </Box>
    </AppBase>
  )
}

export { PaymentsMain }
