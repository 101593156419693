import * as React from 'react'
import Box from '@mui/material/Box'

import { Images } from '@images'
import { Content, Title } from '@components'
import { AppBase } from '@features/AppBase'
import { useTranslation } from '@hooks'

const PrivacyPolicy = () => {
  const { t } = useTranslation()
  const content = ''

  return (
    <AppBase requireActiveSession={false} backgroundImage={Images.MarketplaceBackground}>
      <Box width={'100%'} px={{ md: 16.5, sm: 8, xs: 2 }} py={4}>
        <Title text={t('pages.privacy.title')} />
        <Content flexDirection={{ xl: 'row', lg: 'row', md: 'row', sm: 'row', xs: 'column' }} gap={{ sm: 4, xs: 2.5 }}>
          <p
            style={{ color: 'white' }}
            dangerouslySetInnerHTML={{
              __html: content,
            }}
          ></p>
        </Content>
      </Box>
    </AppBase>
  )
}
export { PrivacyPolicy }
