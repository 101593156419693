import * as React from 'react'

import { useModal } from '@contexts'
import { CustomButton, Modal, TextH2Bold } from '@components'
import { Images } from '@images'
import { useTranslation } from '@hooks'
import { ROUTES } from '@constants'

interface Props {
  onConfirm: () => void
}
const TermsConfirmModal = (props: Props) => {
  const { onConfirm } = props
  const { t } = useTranslation()
  const { dismissModal } = useModal()

  return (
    <Modal.Content>
      <Modal.Header image={Images.WarningImage}>
        <TextH2Bold>
          {t('coins.buy.modals.terms-confirm.title')}{' '}
          <a target={'_blank'} style={{ color: 'white' }} href={ROUTES.PAGE_TERMS}>
            {t('coins.buy.modals.terms-confirm.terms')}
          </a>
        </TextH2Bold>
      </Modal.Header>
      <Modal.Actions>
        <CustomButton
          variant={'secondary'}
          label={t('coins.buy.modals.terms-confirm.back')}
          fullWidth={true}
          onClick={() => dismissModal()}
        />
        <CustomButton
          variant={'primary'}
          label={t('coins.buy.modals.terms-confirm.continue')}
          fullWidth={true}
          onClick={() => {
            onConfirm()
            dismissModal()
          }}
        />
      </Modal.Actions>
    </Modal.Content>
  )
}

export { TermsConfirmModal }
