import { api } from '@api'

import { CoinsBudget, CreatePaymentResponse, Payment } from '../types'

const getCoinsBudget = async (data: CoinsBudget) => {
  const response = await api.post<CoinsBudget>(`/payments/calculate-budget`, data)

  return response.data
}

const createCoinsPayment = async (data: CoinsBudget) => {
  const response = await api.post<CreatePaymentResponse>(`/payments/coins`, data)

  return response.data
}

const createActivationCodePayment = async () => {
  const response = await api.post<CreatePaymentResponse>(`/payments/activation-code`)

  return response.data
}

const getPayments = async () => {
  const response = await api.get<Payment[]>(`/payments`)

  return response.data
}

const requestRefund = async paymentId => {
  await api.post<void>(`/payments/refund`, { paymentId })
}
export const PaymentsApi = {
  getCoinsBudget,
  createCoinsPayment,
  createActivationCodePayment,
  getPayments,
  requestRefund,
}
