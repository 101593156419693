import Box from '@mui/material/Box'

import { Images } from '@images'
import { AppBase } from '@features/AppBase'
import { useWidgets, WidgetComponent } from '@features/Widgets'

import { useDigitizeYourFirstTShirt, useFreemiumUserPoll, usePasswordResetAndEmailValidation } from '../../hooks'
import { AnswerPollToWinPrizeWidget, Banner, DigitizeWidget, LastTShirts } from '../../components'

const LandingMain = () => {
  const { shouldShowWidget: shouldShowAnswerPollToWinPrizeWidget, actionPath } = useFreemiumUserPoll()
  usePasswordResetAndEmailValidation()
  useDigitizeYourFirstTShirt()
  const { data: widgets = [] } = useWidgets()

  return (
    <AppBase requireActiveSession={false} backgroundImage={Images.LandingBackground}>
      <Banner />
      <Box display={'flex'} flexDirection={'column'} gap={4} width={'100%'} px={{ md: 16.5, sm: 8, xs: 2 }} py={8}>
        <Box display={'flex'} flexDirection={'row'} gap={4} width={'100%'} flexWrap={'wrap'}>
          {widgets.map((w, wi) => (
            <WidgetComponent {...w} key={`widget-${wi}`} />
          ))}
        </Box>
        <Box
          display={'flex'}
          flexDirection={{ lg: 'row', md: 'column', sm: 'column', xs: 'column' }}
          gap={4}
          width={'100%'}
          justifyContent={'center'}
        >
          {shouldShowAnswerPollToWinPrizeWidget && <AnswerPollToWinPrizeWidget actionPath={actionPath} />}
        </Box>
        <DigitizeWidget />
        <LastTShirts />
      </Box>
    </AppBase>
  )
}

export { LandingMain }
