import { api } from '@api'

import { Widget } from '../types'

const getWidgets = async () => {
  const response = await api.get<Widget[]>(`/public/widgets`)

  return response.data
}

export const WidgetsApi = { getWidgets }
