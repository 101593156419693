import { useQuery } from 'react-query'

import { QUERY_KEYS } from '@api'
import { useUser } from '@contexts'

import { WidgetsApi } from '../../api'

export const useWidgets = () => {
  const { userState } = useUser()
  const { data, isLoading } = useQuery([QUERY_KEYS.PUBLIC_WIDGETS, userState?.id], WidgetsApi.getWidgets)

  return { data, isLoading }
}
