export enum WidgetType {
  TEXT = 'TEXT',
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
  NEWS_VERTICAL = 'NEWS_VERTICAL',
  NEWS_HORIZONTAL = 'NEWS_HORIZONTAL',
  GLOBAL_RANKING = 'GLOBAL_RANKING',
}

export type ImageWidgetType = {
  type: WidgetType.IMAGE
  imageUrl: string
  actionTarget?: string
}

export type VideoWidgetType = {
  type: WidgetType.VIDEO
  imageUrl?: string
  videoUrl: string
}

type NewsWidgetType = {
  imageUrl: string
  title: string
  description: string
  actionName?: string
  actionTarget?: string
}
export type NewsHorizontalWidgetType = NewsWidgetType & {
  type: WidgetType.NEWS_HORIZONTAL
}

export type NewsVerticalWidgetType = NewsWidgetType & {
  type: WidgetType.NEWS_VERTICAL
}
export type GlobalRankingWidgetType = {
  type: WidgetType.GLOBAL_RANKING
  title: string
  actionName?: string
  actionTarget?: string
}

export type TextWidgetType = {
  type: WidgetType.TEXT
  title: string
  description: string
  actionName?: string
  actionTarget?: string
}

export type Widget =
  | ImageWidgetType
  | VideoWidgetType
  | NewsHorizontalWidgetType
  | NewsVerticalWidgetType
  | GlobalRankingWidgetType
  | TextWidgetType
