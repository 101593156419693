import Box from '@mui/material/Box'
import { useMemo } from 'react'
import dayjs from 'dayjs'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'

import { CustomButton, TextBodyRegular, TextH3Bold } from '@components'
import { useTranslation } from '@hooks'
import { useUser } from '@contexts'
import { ROUTES } from '@constants'

import { useTransactions } from '../../hooks'
import { Transaction, TransactionType } from '../../types'

interface TransactionRowProps {
  transaction: Transaction
}
const TransactionRow = (props: TransactionRowProps) => {
  const { transaction } = props
  const { t } = useTranslation()
  const { userState } = useUser()

  const createdAt = dayjs(transaction.createdAt).tz(dayjs.tz.guess())
  const date = createdAt.format(t('coins.transactions-history.item.date')).toUpperCase()

  const description = useMemo(() => {
    switch (transaction.type) {
      case TransactionType.REFUND:
        return t('coins.transactions-history.item.refund', { amount: Math.abs(transaction.amount) })
      case TransactionType.BUY:
        return t('coins.transactions-history.item.buy', { amount: transaction.amount })
      case TransactionType.GIFT:
        return t('coins.transactions-history.item.gift', { amount: transaction.amount })
      case TransactionType.PRIZE:
        return t('coins.transactions-history.item.prize', { amount: transaction.amount })
      case TransactionType.COLLECTIBLE_EXCHANGE:
        const collectibleId = transaction.collectible?.id ?? '--'
        if (transaction.receiver.id === userState?.id) {
          return t('coins.transactions-history.item.collectible-exchange-sell', {
            amount: transaction.amount,
            collectibleId,
          })
        }

        return t('coins.transactions-history.item.collectible-exchange-purchase', {
          amount: transaction.amount,
          collectibleId,
        })
      default:
        return '---'
    }
  }, [transaction, userState, t])

  return (
    <>
      <TextBodyRegular sx={{ pt: 2 }}>
        {t('coins.transactions-history.item.description', { date, description })}
      </TextBodyRegular>
    </>
  )
}
const TransactionsHistory = () => {
  const { data } = useTransactions()
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <Box width={{ md: '50%', xs: '100%' }}>
      <TextH3Bold>{t('coins.transactions-history.title')}</TextH3Bold>
      <Box>
        {data?.map(transaction => {
          return <TransactionRow transaction={transaction} />
        })}
      </Box>
      <CustomButton
        variant={'secondary'}
        label={t('payments.link')}
        sx={{ mt: 2 }}
        onClick={() => navigate(ROUTES.PAYMENTS)}
      />
    </Box>
  )
}

export { TransactionsHistory }
