import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'

import { colors } from '@styles'

const WidgetContent = styled(Box)(({ theme }) => ({
  borderRadius: theme.spacing(3),
  border: `2px solid ${colors.grayscale[10].background}`,
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  width: '100%',
  backgroundColor: colors.grayscale['100'].background,
  padding: theme.spacing(2),
  justifyContent: 'center',
  flexWrap: 'wrap',
}))

export { WidgetContent }
