import { useMutation, useQuery, useQueryClient } from 'react-query'

import { QUERY_KEYS } from '@api'

import { PaymentsApi } from '../../api'

const usePayments = () => {
  const queryClient = useQueryClient()

  const { data, isLoading } = useQuery([QUERY_KEYS.MY_PAYMENTS], () => PaymentsApi.getPayments(), {
    refetchOnWindowFocus: false,
  })

  const invalidate = () => queryClient.invalidateQueries(QUERY_KEYS.MY_PAYMENTS)

  return { data, isLoading, invalidate }
}

const useRequestRefund = (options: { onSuccess?: () => void; onError?: () => void }) => {
  const { onSuccess, onError } = options
  const queryClient = useQueryClient()

  const invalidate = () => queryClient.invalidateQueries(QUERY_KEYS.MY_PAYMENTS)

  const { mutate: requestRefund, isLoading } = useMutation(
    (paymentId: string) => PaymentsApi.requestRefund(paymentId),
    {
      onSuccess: () => {
        invalidate().then()
        if (onSuccess) onSuccess()
      },
      onError,
    },
  )

  return { isLoading, requestRefund }
}
export { usePayments, useRequestRefund }
