import { ReactElement, useState, MouseEvent, useMemo, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  AppBar,
  Box,
  Collapse,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Toolbar,
  Badge,
  Avatar,
  useMediaQuery,
} from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as React from 'react'

import { useMenuDrawer, useModal, useUser } from '@contexts'
import { useLogout, useTranslation } from '@hooks'
import { CustomButton, DropDownMenu, TextCaptionRegular } from '@components'
import { Images } from '@images'
import { colors, MediaQuery } from '@styles'
import { ROUTES } from '@constants'
import { MedalIcon } from '@features/Icons/MedalIcon'
import { SubscriptionChip } from '@features/AppBase/components/SubscriptionChip'

import { menu } from './menu'
import { LoginModal } from '../../../Login'
import { NotificationsMenu, ProfileMenu, VerticalDivider } from './styled'
import { MqDesktop } from '../../../../styles/theme'

const useMenuOptions = () => {
  const { logout } = useLogout()
  const { setOpen, isOpen } = useMenuDrawer()
  const navigate = useNavigate()
  const { showModal } = useModal()

  const handleDrawerToggle = () => {
    setOpen(prevState => {
      return !prevState
    })
  }

  const onMenuOptionSelected = (id: string) => {
    setOpen(false)
    switch (id) {
      case 'nav.trivia':
        navigate(ROUTES.TRIVIAS)
        break
      case 'nav.challenge':
        navigate(ROUTES.CHALLENGES)
        break
      case 'nav.predictions':
        navigate(ROUTES.PREDICTIONS)
        break
      case 'nav.my-collectibles':
        navigate(ROUTES.MY_COLLECTIBLES)
        break
      case 'nav.my-shirts':
        navigate(ROUTES.MY_SHIRTS)
        break
      case 'nav.marketplace':
        navigate(ROUTES.MARKETPLACE)
        break
      case 'nav.museum':
        navigate('/museum')
        break
      case 'nav.digitize':
        navigate(ROUTES.DIGITIZE)
        break
      case 'nav.login':
        showModal({ modal: <LoginModal /> })
        break
      case 'nav.logout':
        logout()
        navigate('/login')
        break
      case 'nav.home':
        navigate(ROUTES.HOME)
        break
      case 'nav.profile':
        navigate(ROUTES.PROFILE)
        break
      case 'nav.coins':
        navigate(ROUTES.COINS)
        break
      case 'nav.ranking':
        navigate(ROUTES.RANKINGS)
        break
    }
  }

  return { onMenuOptionSelected, handleDrawerToggle, mobileOpen: isOpen }
}

const ProfileMenuWrapper = () => {
  const { t } = useTranslation()

  const { userState } = useUser()

  const [anchorProfileMenu, setAnchorProfileMenu] = useState<null | HTMLElement>(null)
  const openProfile = Boolean(anchorProfileMenu)

  const isLoggedIn = useMemo(() => !!userState, [userState])

  useEffect(() => {
    if (isLoggedIn) {
      setAnchorProfileMenu(null)
    }
  }, [isLoggedIn])

  const handleClickProfile = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorProfileMenu(event.currentTarget)
  }
  const handleCloseProfileMenu = () => {
    setAnchorProfileMenu(null)
  }

  const { onMenuOptionSelected } = useMenuOptions()
  const isDesktop = useMediaQuery(MediaQuery[MqDesktop].replace(/^@media( ?)/m, ''))

  return isDesktop ? (
    <>
      <IconButton
        aria-label='profile'
        edge='start'
        onClick={handleClickProfile}
        sx={{ mr: 2, color: 'gray', position: 'relative' }}
      >
        {userState?.profile?.avatar ? (
          <Avatar
            src={userState?.profile?.avatar}
            sx={{ borderColor: colors.primary.main.background, borderWidth: '2px', borderStyle: 'solid' }}
          />
        ) : (
          <FontAwesomeIcon icon={['far', 'circle-user']} color={'white'} size={'lg'} />
        )}
        <Box sx={{ position: 'absolute', bottom: -6 }}>
          <SubscriptionChip subscriptionType={userState?.subscription.type} />
        </Box>
      </IconButton>

      <ProfileMenu id='profile-menu' anchorEl={anchorProfileMenu} open={openProfile} onClose={handleCloseProfileMenu}>
        <MenuItem onClick={() => onMenuOptionSelected('nav.profile')}>
          <ListItemIcon>
            <FontAwesomeIcon icon={['far', 'user']} color={colors.grayscale[10].background} />
          </ListItemIcon>
          <TextCaptionRegular>{t('nav.my-profile')}</TextCaptionRegular>
        </MenuItem>
        <MenuItem onClick={() => onMenuOptionSelected('nav.coins')}>
          <ListItemIcon>
            <FontAwesomeIcon icon={'coins'} color={colors.grayscale[10].background} />
          </ListItemIcon>
          <TextCaptionRegular>{userState?.coins}</TextCaptionRegular>
        </MenuItem>
        <MenuItem onClick={() => onMenuOptionSelected('nav.ranking')}>
          <ListItemIcon sx={{ color: colors.grayscale[10].background }}>
            <MedalIcon size={18} />
          </ListItemIcon>
          <TextCaptionRegular>{t('nav.ranking')}</TextCaptionRegular>
        </MenuItem>
        <MenuItem onClick={() => onMenuOptionSelected('nav.logout')}>
          <ListItemIcon>
            <FontAwesomeIcon icon={'arrow-right-from-bracket'} color={colors.grayscale[10].background} />
          </ListItemIcon>
          <TextCaptionRegular>{t('nav.logout')}</TextCaptionRegular>
        </MenuItem>
      </ProfileMenu>
    </>
  ) : (
    <>
      <Divider color={'#A3A3A3'} />
      <ListItemButton
        sx={{ textAlign: 'left' }}
        onClick={() => {
          onMenuOptionSelected('nav.profile')
        }}
      >
        <ListItemIcon sx={{ minWidth: 32 }}>
          <FontAwesomeIcon icon={['far', 'user']} color={'white'} />
        </ListItemIcon>
        <ListItemText primary={t('nav.my-profile')} sx={{ color: 'white' }} />
        <Box sx={{ position: 'absolute', right: 16 }}>
          <SubscriptionChip subscriptionType={userState?.subscription.type} />
        </Box>
      </ListItemButton>
      <ListItemButton
        sx={{ textAlign: 'left' }}
        onClick={() => {
          onMenuOptionSelected('nav.coins')
        }}
      >
        <ListItemIcon sx={{ minWidth: 32 }}>
          <FontAwesomeIcon icon={'coins'} color={'white'} />
        </ListItemIcon>
        <ListItemText primary={userState?.coins} sx={{ color: 'white' }} />
      </ListItemButton>
      <ListItemButton
        sx={{ textAlign: 'left' }}
        onClick={() => {
          onMenuOptionSelected('nav.ranking')
        }}
      >
        <ListItemIcon sx={{ minWidth: 32, color: 'white' }}>
          <MedalIcon size={20} />
        </ListItemIcon>
        <ListItemText primary={t('nav.ranking')} sx={{ color: 'white' }} />
      </ListItemButton>
      <ListItemButton
        sx={{ textAlign: 'left' }}
        onClick={() => {
          onMenuOptionSelected('nav.logout')
        }}
      >
        <ListItemIcon sx={{ minWidth: 32 }}>
          <FontAwesomeIcon icon={'arrow-right-from-bracket'} color={'white'} />
        </ListItemIcon>
        <ListItemText primary={t('nav.logout')} sx={{ color: 'white' }} />
      </ListItemButton>
    </>
  )
}

const NotificationsMenuWrapper = () => {
  const [anchorNotificationsMenu, setAnchorNotificationsMenu] = useState<null | HTMLElement>(null)
  const openNotifications = Boolean(anchorNotificationsMenu)
  const { userState } = useUser()

  const isLoggedIn = useMemo(() => !!userState, [userState])

  useEffect(() => {
    if (isLoggedIn) {
      setAnchorNotificationsMenu(null)
    }
  }, [isLoggedIn])

  const handleClickNotifications = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorNotificationsMenu(event.currentTarget)
  }

  const handleCloseNotificationsMenu = () => {
    setAnchorNotificationsMenu(null)
  }

  return (
    <>
      <IconButton
        aria-label='notifications'
        edge='start'
        onClick={handleClickNotifications}
        sx={{ mr: 2, color: 'gray' }}
      >
        <Badge variant='dot' invisible={true} color='secondary'>
          <FontAwesomeIcon icon={['far', 'bell']} color={'white'} size={'lg'} />
        </Badge>
      </IconButton>
      <NotificationsMenu
        id='notifications-menu'
        anchorEl={anchorNotificationsMenu}
        open={openNotifications}
        onClose={handleCloseNotificationsMenu}
      >
        <MenuItem onClick={handleCloseNotificationsMenu}>
          <TextCaptionRegular>Nueva trivia disponible</TextCaptionRegular>
        </MenuItem>
        <MenuItem onClick={handleCloseNotificationsMenu}>
          <TextCaptionRegular>Nuevo desafío</TextCaptionRegular>
        </MenuItem>
        <MenuItem onClick={handleCloseNotificationsMenu}>
          <TextCaptionRegular>Vendiste un coleccionable</TextCaptionRegular>
        </MenuItem>
        <MenuItem onClick={handleCloseNotificationsMenu}>
          <TextCaptionRegular>Mira los resultados del partido</TextCaptionRegular>
        </MenuItem>

        {/*
          <HorizontalDivider />
          <Row>
          <CustomButton
            key={'notifications-btn'}
            variant={'primary'}
            label={t('nav.go-to-notifications')}
            onClick={() => navigate('/notifications')}
          />
        </Row>*/}
      </NotificationsMenu>
    </>
  )
}

const MenuDrawer = () => {
  const { t } = useTranslation()

  const { userState } = useUser()

  const isLoggedIn = useMemo(() => !!userState, [userState])

  const [openMenu, updateOpenMenu] = useState<string | null>(null)

  const openSubMenu = (id: string) => {
    if (id === openMenu) {
      updateOpenMenu(null)
    } else {
      updateOpenMenu(id)
    }
  }

  const { handleDrawerToggle, onMenuOptionSelected } = useMenuOptions()

  return (
    <Box>
      <Box
        display={'flex'}
        flexDirection={'row'}
        justifyContent={isLoggedIn ? 'center' : 'space-around'}
        alignItems={'center'}
      >
        <Toolbar sx={{ width: 0, padding: 0 }} />
        <Box display={{ xs: 'flex', md: 'none' }} sx={{ flexGrow: 1, alignItems: 'center' }}>
          <IconButton aria-label='open drawer' sx={{ mr: 2, p: 2, color: 'gray' }} onClick={handleDrawerToggle}>
            <FontAwesomeIcon icon={'close'} color={'white'} />
          </IconButton>
          <img src={Images.NavIcon} alt='nav icon' height={20} onClick={() => onMenuOptionSelected('nav.home')} />
        </Box>
        {!isLoggedIn && (
          <CustomButton
            key={'login-btn'}
            variant={'primary'}
            sx={{ mr: 2 }}
            label={t('nav.login')}
            onClick={() => onMenuOptionSelected('nav.login')}
          />
        )}
        {isLoggedIn && <NotificationsMenuWrapper />}
      </Box>
      <Divider color={'#A3A3A3'} />
      <List>
        {menu.map((item, itemIndex) => {
          const withSubMenu = !!item.subMenu && item.subMenu.length > 0

          return (
            <Box key={`box-${itemIndex}`}>
              <ListItemButton
                key={`ListItemButton-${item.id}-${itemIndex}`}
                sx={{ textAlign: 'left' }}
                onClick={e => {
                  if (withSubMenu) {
                    openSubMenu(item.id)
                  } else {
                    onMenuOptionSelected(item.id)
                  }
                  e.preventDefault()
                  e.stopPropagation()
                }}
              >
                {!!item.icon && (
                  <ListItemIcon>
                    <FontAwesomeIcon icon={item.icon} color={'white'} />
                  </ListItemIcon>
                )}
                <ListItemText
                  primary={t(item.label)}
                  sx={{ color: openMenu === item.id ? colors.primary.light.background : 'white' }}
                />
                {!withSubMenu ? null : openMenu === item.id ? (
                  <FontAwesomeIcon icon={'chevron-down'} color={colors.primary.light.background} />
                ) : (
                  <FontAwesomeIcon icon={'chevron-right'} color={'white'} />
                )}
              </ListItemButton>
              {withSubMenu && (
                <Collapse
                  key={`Collapse-${item.id}-${itemIndex}-sub-menu`}
                  in={openMenu === item.id}
                  timeout='auto'
                  unmountOnExit
                >
                  <List component='div' disablePadding>
                    {!!item.subMenu &&
                      item.subMenu.map((subItem, subItemIndex) => (
                        <ListItemButton
                          sx={{ pl: 4 }}
                          key={`Collapse-ListItemButton-${item.id}-${itemIndex}-${subItemIndex}`}
                        >
                          {!!subItem.icon && (
                            <ListItemIcon>
                              <FontAwesomeIcon icon={subItem.icon} color={'white'} />
                            </ListItemIcon>
                          )}
                          <ListItemText
                            primary={t(subItem.label)}
                            sx={{ color: 'white' }}
                            onClick={() => onMenuOptionSelected(subItem.id)}
                          />
                        </ListItemButton>
                      ))}
                  </List>
                </Collapse>
              )}
            </Box>
          )
        })}
        {isLoggedIn && <ProfileMenuWrapper />}
      </List>
    </Box>
  )
}

const NavbarPanel = () => {
  const { t } = useTranslation()

  const { userState } = useUser()

  const isLoggedIn = useMemo(() => !!userState, [userState])

  const [openDropDown, setOpenDropDown] = useState<null | string>(null)
  const [mousePosition, setMousePosition] = useState<{ mouseX: number; mouseY: number }>({ mouseX: 0, mouseY: 0 })

  const { handleDrawerToggle, onMenuOptionSelected, mobileOpen } = useMenuOptions()

  return (
    <>
      <AppBar
        key={'app-bar'}
        position='absolute'
        elevation={0}
        sx={{
          borderBottom: `1px solid ${colors.grayscale['30'].background}`,
          background: colors.grayscale['80'].background,
        }}
      >
        <Toolbar>
          <Box display={{ xs: 'flex', md: 'none' }} sx={{ flexGrow: 1, alignItems: 'center' }}>
            <IconButton
              aria-label='open drawer'
              edge='start'
              onClick={handleDrawerToggle}
              sx={{ mr: 2, color: 'gray' }}
            >
              <FontAwesomeIcon icon={'bars'} color={'white'} />
            </IconButton>
            <img src={Images.NavIcon} alt='nav icon' height={20} onClick={() => onMenuOptionSelected('nav.home')} />
          </Box>

          <Box
            sx={{ flexGrow: 1, display: { xs: 'none', sm: 'none', md: 'flex' }, alignItems: 'center' }}
            onMouseMove={event => setMousePosition({ mouseX: event.clientX, mouseY: event.clientY })}
          >
            <img
              src={Images.NavIcon}
              alt='nav icon'
              height={20}
              style={{ paddingRight: 32 }}
              onClick={() => onMenuOptionSelected('nav.home')}
            />

            {menu
              .filter(item => (!!item.userRequired ? isLoggedIn : true))
              .map((item, index) => {
                let element: ReactElement | null = null
                if (item.subMenu && item.subMenu.length > 0) {
                  element = (
                    <DropDownMenu
                      setOpenMenu={setOpenDropDown}
                      openMenu={openDropDown}
                      label={t(item.label)}
                      key={`DropDownMenu-${item.id}-${index}`}
                      keyProp={`DropDownMenu-${item.id}-${index}`}
                      options={item.subMenu.map(subItem => ({
                        ...subItem,
                        label: t(subItem.label),
                        onClick: () => onMenuOptionSelected(subItem.id),
                      }))}
                      mouseX={mousePosition.mouseX}
                      mouseY={mousePosition.mouseY}
                    />
                  )
                } else {
                  element = (
                    <CustomButton
                      variant={'text'}
                      label={t(item.label)}
                      key={`${item.id}-${index}-btn`}
                      onClick={() => onMenuOptionSelected(item.id)}
                    />
                  )
                }

                if (index + 1 < menu.length) {
                  return [element, <VerticalDivider key={`divider-${index + 1}`} />]
                } else return element
              })}
          </Box>

          <Box sx={{ flexGrow: 0, display: { xs: 'none', sm: 'none', md: 'flex' }, alignItems: 'center' }}>
            {isLoggedIn && (
              <>
                <NotificationsMenuWrapper />
                <ProfileMenuWrapper />
              </>
            )}
            {!isLoggedIn && (
              <CustomButton
                key={'login-btn'}
                variant={'primary'}
                label={t('nav.login')}
                onClick={() => onMenuOptionSelected('nav.login')}
              />
            )}
          </Box>
          <Box sx={{ flexGrow: 0, display: { xs: 'flex', md: 'none' }, alignItems: 'center' }}>
            {isLoggedIn && (
              <>
                <NotificationsMenuWrapper />
              </>
            )}
            {!isLoggedIn && (
              <CustomButton
                variant={'primary'}
                label={t('nav.login')}
                onClick={() => onMenuOptionSelected('nav.login')}
                key={'login-btn'}
              />
            )}
          </Box>
        </Toolbar>
      </AppBar>
      <nav key={'nav-bar'}>
        <Drawer
          variant='temporary'
          open={mobileOpen}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { sm: 'block', md: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '100%' },
          }}
        >
          <MenuDrawer />
        </Drawer>
      </nav>
    </>
  )
}

export { NavbarPanel }
