import { default as NavIcon } from './nav-icon.png'
import { default as LandingBackground } from './landing.jpg'
import { default as GenericBackground } from './background.jpg'
import { default as ConfirmImage } from './generic-confirm.png'
import { default as SuccessImage } from './generic-success.png'
import { default as ErrorImage } from './generic-error.png'
import { default as ForgotPasswordErrorImage } from './forgot-password-error.png'
import { default as LoginImage } from './login.png'
import { default as ForgotPasswordImage } from './forgot-password.png'
import { default as CreateAccountImage } from './create-account.png'
import { default as CreateAccountBackground } from './create-account-background.jpg'
import { default as CreateAccountError } from './create-account-error.png'
import { default as CreateAccountSuccess } from './create-account-success.png'
import { default as TriviaCompletedImage } from './trivia-completed.png'
import { default as EditProfileSuccessImage } from './edit-profile-success.png'
import { default as LandingTShirts } from './landing-tshirts.jpg'
import { default as RankingBackground } from './bg_ranking.jpg'
import { default as BuyCoinsSuccess } from './buy_coins_success.jpg'
import { default as BuyCoinsError } from './buy_coins_error.jpg'
import { default as PutYourShirtsToPlay } from './put-your-shirts-to-play.jpg'
import { default as WarningImage } from './generic-warning.png'
import { default as InformativeImage } from './generic-informative.png'
import { default as NeutralImage } from './generic-neutral.png'
import { default as PoolThumbnail } from './pool-thumbnail.png'
import { default as WidgetRankingImage } from './widget-ranking.jpg'

export const theme = {
  NavIcon,

  Background: GenericBackground,
  ProfileBackground: GenericBackground,
  WalletBackground: GenericBackground,
  ChallengesBackground: GenericBackground,
  CollectiblesBackground: GenericBackground,
  DigitizeBackground: GenericBackground,
  GalleryBackground: GenericBackground,
  MarketplaceBackground: GenericBackground,
  MuseumBackground: GenericBackground,
  PredictionsBackground: GenericBackground,
  TriviasBackground: GenericBackground,
  PollsBackground: GenericBackground,
  LandingBackground,
  RankingBackground,
  CreateAccountBackground,
  LandingTShirts,
  LoginImage,
  ConfirmImage,
  SuccessImage,
  ErrorImage,
  ForgotPasswordErrorImage,
  ForgotPasswordImage,
  CreateAccountImage,
  CreateAccountError,
  CreateAccountSuccess,
  TriviaCompletedImage,
  PollOptionSavedImage: ConfirmImage,
  EditProfileSuccessImage,
  BuyCoinsError,
  BuyCoinsSuccess,
  PutYourShirtsToPlay,
  WarningImage,
  InformativeImage,
  NeutralImage,
  PoolThumbnail,
  WidgetRankingImage,
}
