import * as React from 'react'

import { CustomButton, TextH1Bold, TextSubtitleRegular } from '@components'
import { TextWidgetType } from '@features/Widgets/types'

import { WidgetContent } from '../WidgetContent'

const TextWidget = (props: Omit<TextWidgetType, 'type'>) => {
  const { title, description, actionName, actionTarget } = props

  const onClick = actionTarget ? () => window.location.replace(actionTarget) : undefined

  return (
    <WidgetContent>
      <TextH1Bold sx={{ pb: 3 }}>{title}</TextH1Bold>

      {!!description && <TextSubtitleRegular>{description}</TextSubtitleRegular>}
      {!!actionName && !!onClick && (
        <CustomButton
          variant={'secondary'}
          label={actionName}
          sx={{ mt: 2, fontWeight: 700, fontSize: 18 }}
          onClick={onClick}
        />
      )}
    </WidgetContent>
  )
}

export { TextWidget }
