import {
  GlobalRankingWidgetType,
  ImageWidgetType,
  NewsHorizontalWidgetType,
  NewsVerticalWidgetType,
  TextWidgetType,
  VideoWidgetType,
  Widget as TWidget,
  WidgetType,
} from '../../types'
import { VideoWidget } from '../VideoWidget'
import { ImageWidget } from '../ImageWidget'
import { NewsVerticalWidget } from '../NewsVerticalWidget'
import { NewsHorizontalWidget } from '../NewsHorizontalWidget'
import { GlobalRankingWidget } from '../GlobalRankingWidget'
import { TextWidget } from '../TextWidget'

const Widget = (props: TWidget) => {
  const { type, ...widgetProps } = props

  switch (type) {
    case WidgetType.VIDEO:
      return <VideoWidget {...(widgetProps as VideoWidgetType)} />
    case WidgetType.IMAGE:
      return <ImageWidget {...(widgetProps as ImageWidgetType)} />
    case WidgetType.NEWS_VERTICAL:
      return <NewsVerticalWidget {...(widgetProps as NewsVerticalWidgetType)} />
    case WidgetType.NEWS_HORIZONTAL:
      return <NewsHorizontalWidget {...(widgetProps as NewsHorizontalWidgetType)} />
    case WidgetType.GLOBAL_RANKING:
      return <GlobalRankingWidget {...(widgetProps as GlobalRankingWidgetType)} />
    case WidgetType.TEXT:
      return <TextWidget {...(widgetProps as TextWidgetType)} />
    default:
      return <></>
  }
}

export { Widget }
