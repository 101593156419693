import { styled, Theme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import { MouseEventHandler, PropsWithChildren } from 'react'
import { SxProps, useMediaQuery } from '@mui/material'

import { colors, MediaQuery, MqDesktopXL } from '@styles'

const Content = styled(Box)(({ theme }) => ({
  borderRadius: theme.spacing(3),
  border: `2px solid ${colors.grayscale[10].background}`,
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  backgroundColor: colors.grayscale['100'].background,
  padding: theme.spacing(2),
  justifyContent: 'space-between',
  flexBasis: '100%',
}))

interface Props extends PropsWithChildren {
  sx?: SxProps<Theme>
  onClick?: MouseEventHandler<HTMLElement>
}
const WidgetContent = (props: Props) => {
  const { sx, onClick } = props
  const isDesktop = useMediaQuery(MediaQuery[MqDesktopXL].replace(/^@media( ?)/m, ''))

  return (
    <Content maxWidth={theme => (isDesktop ? `calc(50% - ${theme.spacing(2)})` : '100%')} sx={sx} onClick={onClick}>
      {props.children}
    </Content>
  )
}
export { WidgetContent }
