import * as React from 'react'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  TableSortLabel,
} from '@mui/material'
import Box from '@mui/material/Box'
import { useMemo } from 'react'

import { PaginatedResponse } from '@types'
import { colors } from '@styles'
import { RoundedCornersBox, TextBodyRegular } from '@components'

import { TableColumns, TableActions } from './types'

type Order = 'asc' | 'desc'

interface EnhancedTableProps<T> {
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void
  order: Order
  orderBy: string
  columns: (TableColumns<T> | TableActions<T>)[]
}

const EnhancedTableHead = <T,>(props: EnhancedTableProps<T>) => {
  const { order, orderBy, onRequestSort, columns } = props
  const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property)
  }

  return (
    <TableRow
      sx={{
        backgroundColor: colors.grayscale[40].background,
        borderTopLeftRadius: '14px',
        borderTopRightRadius: '14px',
      }}
    >
      {columns.map(headerCol => (
        <TableCell
          key={headerCol.field}
          padding={'none'}
          sx={{
            p: 1,
            width: headerCol.width,
            border: 'none',
          }}
          sortDirection={orderBy === headerCol.field ? order : false}
        >
          {headerCol.type == 'column' &&
            (headerCol.sorteable ? (
              <TableSortLabel
                sx={{
                  color: 'white !important',
                  '&.Mui-active': {
                    color: 'white',
                  },
                  '& > .MuiTableSortLabel-icon': {
                    opacity: 0.5,
                    color: 'white !important',
                  },
                }}
                active={orderBy === headerCol.field}
                direction={orderBy === headerCol.field ? order : 'asc'}
                onClick={createSortHandler(headerCol.field)}
              >
                {headerCol.headerName}
              </TableSortLabel>
            ) : (
              <TextBodyRegular sx={{ fontWeight: 500 }}>{headerCol.headerName}</TextBodyRegular>
            ))}
        </TableCell>
      ))}
    </TableRow>
  )
}

interface Props<T> {
  columns: (TableColumns<T> | TableActions<T>)[]
  data: PaginatedResponse<T>
  onChangePage: (page: number) => void
  onRowClicked?: (_: React.MouseEvent<unknown>, item: T) => void
  borderColor?: string
  extraItemComparator?: (item: T, extraItem?: T) => boolean
  hidePagination?: boolean
  minWidth?: number | string
}

interface CustomTableRowProps<T> {
  item: T
  index: number
  extraItemIndex: number
  data: PaginatedResponse<T>
  onRowClicked?: (_: React.MouseEvent<unknown>, item: T) => void
  columns: (TableColumns<T> | TableActions<T>)[]
}
const CustomTableRow = <T,>(props: CustomTableRowProps<T>) => {
  const { onRowClicked, columns, data, item, index, extraItemIndex } = props

  return (
    <TableRow
      hover
      onClick={event => (onRowClicked ? onRowClicked(event, item) : {})}
      tabIndex={-1}
      key={`${data.page}-${index}`}
      sx={{
        cursor: 'pointer',
        backgroundColor: extraItemIndex === index ? colors.grayscale['60'].background : 'unset',
        '&.MuiTableRow-hover:hover': {
          backgroundColor: extraItemIndex === index ? colors.grayscale['60'].background : 'unset',
        },
      }}
    >
      {columns.map(column => {
        if (column.type === 'actions') {
          return (
            <TableCell key={column.field} component='th' scope='row' padding='none' sx={{ p: 1, width: column.width }}>
              {column.getActions(item)}
            </TableCell>
          )
        } else {
          return (
            <TableCell key={column.field} component='th' scope='row' padding='none' sx={{ p: 1, width: column.width }}>
              {column.renderCell(item, column.getValue(item))}
            </TableCell>
          )
        }
      })}
    </TableRow>
  )
}

const PaginatedTable = <T,>(props: Props<T>) => {
  const {
    data,
    onChangePage,
    columns,
    onRowClicked,
    borderColor = colors.primary.main.background,
    extraItemComparator,
    hidePagination = false,
    minWidth = 750,
  } = props
  const { extraItem } = data
  const [order, setOrder] = React.useState<Order>('asc')
  const [orderBy, setOrderBy] = React.useState<string>('date')
  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const extraItemIndex = useMemo(() => {
    let index = -1
    if (extraItemComparator && extraItem) {
      let itemIndex = 0
      for (const item of data.data) {
        if (extraItemComparator(item, extraItem)) {
          index = itemIndex
          break
        }
        itemIndex++
      }
    }

    return index
  }, [data, extraItemComparator])

  return (
    <RoundedCornersBox
      display={'flex'}
      flexDirection={'column'}
      gap={0}
      width={'100%'}
      sx={{ borderColor, overflow: 'hidden' }}
    >
      <Box
        sx={{
          width: '100%',
          backgroundColor: colors.grayscale['80'].background,
          borderBottomLeftRadius: '14px',
          borderBottomRightRadius: '14px',
        }}
      >
        <Paper
          sx={{
            width: '100%',
            backgroundColor: colors.grayscale['80'].background,
            borderBottomLeftRadius: '14px',
            borderBottomRightRadius: '14px',
            overflow: 'hidden',
          }}
        >
          <TableContainer>
            <Table sx={{ minWidth, width: '100%' }} aria-labelledby='tableTitle' size={'medium'}>
              <TableBody>
                <EnhancedTableHead
                  columns={columns}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                />

                {data.data.map((item, index) => {
                  return (
                    <CustomTableRow
                      key={`CustomTableRow-${index}`}
                      item={item}
                      index={index}
                      extraItemIndex={extraItemIndex}
                      columns={columns}
                      data={data}
                      onRowClicked={onRowClicked}
                    />
                  )
                })}
                {extraItemComparator && extraItem && extraItemIndex == -1 && (
                  <CustomTableRow
                    key={`CustomTableRow-extraItem`}
                    item={extraItem}
                    index={-1}
                    extraItemIndex={-1}
                    columns={columns}
                    data={data}
                    onRowClicked={onRowClicked}
                  />
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {!hidePagination && (
            <TablePagination
              sx={{
                backgroundColor: colors.grayscale['80'].background,
                color: `${colors.grayscale['10'].background} !important`,
              }}
              rowsPerPageOptions={[data.pageSize]}
              component='div'
              count={data.total}
              rowsPerPage={data.pageSize}
              page={data.page}
              onPageChange={(_event, page) => onChangePage(page)}
            />
          )}
        </Paper>
      </Box>
    </RoundedCornersBox>
  )
}

export { PaginatedTable }
