import * as React from 'react'

import { CustomButton } from '@components'
import { useSessionUserUpdater, useTranslation } from '@hooks'
import { useModal } from '@contexts'

import { TermsConfirmModal } from '../../modals'
import { useActivationCodePayment } from '../../hooks/useActivationCodePayment'

interface Props {
  onSuccess: () => void
}
const BuyActivationCodeButton = (props: Props) => {
  const { t } = useTranslation()
  const { onSuccess } = props
  const { showModal } = useModal()
  const { refresh } = useSessionUserUpdater()
  const { payActivationCode, isLoading } = useActivationCodePayment(() => {
    refresh()
    onSuccess()
  })

  const makePayment = () => {
    showModal({ modal: <TermsConfirmModal onConfirm={() => payActivationCode()} /> })
  }

  return (
    <CustomButton
      variant={'primary'}
      label={t('coins.buy.activation-code-button')}
      disabled={isLoading}
      onClick={makePayment}
    />
  )
}

export { BuyActivationCodeButton }
