import * as React from 'react'

import { ImageWidgetType } from '@features/Widgets/types'

import { WidgetContent } from '../WidgetContent'

const ImageWidget = (props: Omit<ImageWidgetType, 'type'>) => {
  const { imageUrl, actionTarget } = props

  const onClick = actionTarget ? () => window.location.replace(actionTarget) : undefined

  return (
    <WidgetContent
      sx={{ padding: 0, overflow: 'hidden', cursor: actionTarget ? 'pointer' : 'default' }}
      onClick={onClick}
    >
      <img src={imageUrl} style={{ width: '100%', height: '100%', objectFit: 'cover' }} alt={'image widget'} />
    </WidgetContent>
  )
}

export { ImageWidget }
