import * as React from 'react'
import Box from '@mui/material/Box'

import { CustomButton, TextH2Bold, TextSubtitleRegular } from '@components'
import { colors } from '@styles'
import { NewsVerticalWidgetType } from '@features/Widgets/types'

import { WidgetContent } from '../WidgetContent'

const NewsVerticalWidget = (props: Omit<NewsVerticalWidgetType, 'type'>) => {
  const { title, description, actionName, imageUrl, actionTarget } = props

  const onClick = actionTarget ? () => window.location.replace(actionTarget) : undefined

  return (
    <WidgetContent>
      <Box sx={{ width: '100%', position: 'relative' }}>
        <img
          src={imageUrl}
          style={{ border: `1px solid ${colors.grayscale['30'].background}`, borderRadius: 12, width: '100%' }}
          alt={'t-shirts'}
        />
        <Box sx={{ position: 'absolute', padding: 2, bottom: 0 }}>
          <TextH2Bold>{title}</TextH2Bold>
        </Box>
      </Box>
      {!!description && <TextSubtitleRegular>{description}</TextSubtitleRegular>}
      {!!actionName && !!onClick && (
        <CustomButton
          variant={'secondary'}
          label={actionName}
          sx={{ mt: 2, fontWeight: 700, fontSize: 18 }}
          onClick={onClick}
        />
      )}
    </WidgetContent>
  )
}

export { NewsVerticalWidget }
