import Box from '@mui/material/Box'
import * as React from 'react'
import { useMemo } from 'react'
import dayjs from 'dayjs'

import { CustomButton, TextBodyRegular, TextCaptionRegular, TextH3Bold } from '@components'
import { useTranslation } from '@hooks'
import { useModal, useUser } from '@contexts'
import { RequestRefundConfirmModal } from '@features/Coins/modals'

import { usePayments } from '../../hooks/usePayments'
import { Payment, PaymentStatus, ProductType } from '../../types'

interface PaymentRowProps {
  payment: Payment
}
const PaymentRow = (props: PaymentRowProps) => {
  const { payment } = props
  const { t } = useTranslation()
  const { showModal } = useModal()
  const createdAt = dayjs(payment.createdAt).tz(dayjs.tz.guess())
  const date = createdAt.format(t('payments.payments-history.item.date')).toUpperCase()
  const { userState } = useUser()

  const product = useMemo(() => {
    switch (payment.productType) {
      case ProductType.COINS:
        return t('payments.payments-history.item.product-type.coins', { amount: payment.coins })
      case ProductType.ACTIVATION_CODE:
        return t('payments.payments-history.item.product-type.activation-code')
      default:
        return '---'
    }
  }, [payment, t])

  const price = useMemo(() => {
    const amount = payment.amount / 100

    return t('payments.payments-history.item.price', { amount, currency: payment.currency })
  }, [payment, t])

  const status = useMemo(() => {
    return t(`payments.payments-history.item.status.${payment.status.toLowerCase()}`)
  }, [payment, t])

  const action = useMemo(() => {
    if (
      payment.status === PaymentStatus.SUCCESS &&
      (payment.productType == ProductType.ACTIVATION_CODE || (userState?.coins ?? 0) >= payment.coins)
    ) {
      return (
        <CustomButton
          variant={'secondary'}
          label={t('payments.payments-history.item.actions.request-refund')}
          onClick={() => {
            if (payment.paymentId) {
              showModal({ modal: <RequestRefundConfirmModal paymentId={payment.paymentId} /> })
            }
          }}
        />
      )
    }

    return null
  }, [payment, t, showModal, userState])

  return (
    <Box display={'flex'} flexDirection={'row'} gap={2} alignItems={'center'} sx={{ pt: 2 }}>
      <TextBodyRegular>{`${date} - ${product} - ${price} - ${status}`}</TextBodyRegular>
      {action}
    </Box>
  )
}
const PaymentsHistory = () => {
  const { data, isLoading } = usePayments()
  const { t } = useTranslation()

  return (
    <Box width={{ md: '50%', xs: '100%' }}>
      <TextH3Bold>{t('payments.payments-history.title')}</TextH3Bold>
      <Box>
        {data?.map(payment => {
          return <PaymentRow payment={payment} />
        })}
      </Box>
      {!isLoading && data && data.length == 0 && (
        <Box alignSelf={'center'} mt={4}>
          <TextCaptionRegular>{t('payments.payments-history.empty')}</TextCaptionRegular>
        </Box>
      )}
    </Box>
  )
}

export { PaymentsHistory }
